<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.80308 3.46967C9.09598 3.17678 9.57085 3.17678 9.86375 3.46967L13.8637 7.46967C14.1566 7.76256 14.1566 8.23744 13.8637 8.53033L9.86375 12.5303C9.57085 12.8232 9.09598 12.8232 8.80308 12.5303C8.51019 12.2374 8.51019 11.7626 8.80308 11.4697L11.5228 8.75H2.66675C2.25253 8.75 1.91675 8.41421 1.91675 8C1.91675 7.58579 2.25253 7.25 2.66675 7.25H11.5228L8.80308 4.53033C8.51019 4.23744 8.51019 3.76256 8.80308 3.46967Z"
        fill="currentColor" />
    </svg>
  </i>
</template>
<style scoped lang="scss">
i {
  width: 16px;
  height: 16px;
  display: inline-block;
}
</style>
